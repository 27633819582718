<!--
 * @Description: 站点导航组件
 * @Author: F-Stone
 * @LastEditTime: 2023-10-16 13:11:30
-->
<template>
  <div :class="[$style['m-site-nav'], headType ? $style['m-site'] : '']">
    <div v-for="(item, index) in navList" :key="index" :class="$style['nav-item']">
      <template v-if="item.sub">
        <div :class="[$style['item-inner'], index == 0 ? $style['itemActive'] : '']" class="flex items-center">
          <span class="text"> {{ item.title }}</span>
          <UeIcon :class="$style['ic']" name="arrow-down"/>
        </div>
        <SiteNavSub :width="item.title
                    == 'SCS' ? '350px' : '310px'" :tradeType="item.trade"  :headType="headType" :class="$style['nav-item--sub']" distance="8px"
                    :sub-nav-list="item.sub"/>
      </template>
      <a v-else @click="firstLink(item)" :class="$style['item-inner']">
        <span class="text"> {{ item.title }}</span>
      </a>
    </div>
    <div :class="$style['btn--show-service']">
      <div :class="$style['dot-group']" class="grid grid-cols-3">
        <template v-for="i in 3">
          <div v-for="j in 3" :key="i + '-' + j" :class="$style['dot']" :style="{
                        '--delay': i + j - 2,
                    }"></div>
        </template>
      </div>
      <SiteNavSub :headType="headType" :class="$style['service-nav']" distance="20px"
                  :sub-nav-list="serviceNavList"/>
    </div>
  </div>
</template>
<script>
import SiteNavSub from "./SiteNavSub.vue";
import {mapGetters} from 'vuex'
import urls from '@/utils/baseUrl'

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    },
    navList: {
      type: Array,
      default: []
    },
    headType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      linkUrl: urls.linkUrl,
    }
  },
  computed: {
    ...mapGetters([
      'chainConfig',
      'look',
      'currency',
      'languageName',
      'token',
      'userInfo',
      'levelConfig',
      'styleType',
      'language',
      'isDirection',
      'walletAddress',
      'walletChainId',
      'invitationCode'
    ]),
    serviceNavList() {
      return [
        {
          icon: "icon-a-DAOnetwork",
          title: this.$t('400234'),  //DAO 网络
          desc: this.$t('122000'),
          link: "/dao",
          type: 1
        },
        {
          icon: "icon-Invite",
          title: this.$t('400236'), //邀请好友
          desc: this.$t('122001'),
          link: "/userCenter/commission",
          type: 1
        },
        {
          icon: "icon-Roadmap1",
          title: this.$t('400235'), //Roadmap
          desc: this.$t('122002'),
          link: "/roadmap",
          type: 1
        },
        {
          icon: "icon-a-HelpCenter",
          title: this.$t('400237'), //帮助中心
          desc: this.$t('122003'),
          link: this.language == 'zh-TC'
              ? 'https://support.superex.com/hc/zh-hk'
              : this.language == 'ko'
                  ? 'https://support.superex.com/hc/ko'
                  : 'https://support.superex.com/hc/en-001',
          type: 2
        },
        {
          icon: "icon-a-TaskCenter",
          title: this.$t('400245'), //任务中心
          desc: this.$t('122004'),
          link: "/noviceGuide/sevenDay",
          type: 1
        },
        {
          icon: "icon-a-ETZone",
          title: this.$t('400291'), // ET 专区
          desc: this.$t('122005'),
          link: "/advanced/et",
          type: 1
        },
        {
          icon: "icon-manghe1",
          title: this.$t('122028'), // 盲盒
          desc: this.$t('122027'),
          link: "/advanced/activity/lottery",
          type: 1
        },
        {
          icon: "icon-dailishang",
          title: this.$t('410001'), // 代理商
          desc: this.$t('122007'),
          link: "",
          type: 4
        },
        {
          icon: "icon-a-Frame1171275673",
          title: 'BTC Startup',
          desc: this.$t('8000154'), // BTC协议市场
          name: "markets",
          link: "/advanced/markets?btcStartup=1",
          type: 1
        },
        /* {
           icon: "icon-a-Frame1171274985",
           title: this.$t('8000153'), // 现货自由区上币
           desc: this.$t('8000154'), // 让您的币种在交易所上币
           name: "listingToken",
           link: "/freeMarket/listingToken",
           type: 1
         },*/
      ]
    },
  },
  components: {
    SiteNavSub
  },
  methods: {
    firstLink(item) {
      let invitationCode = this.invitationCode ? '?' + `invitationCode=${this.invitationCode}` : ''
      if (item.title != "Space") {
        window.location.href = `${this.linkUrl + item.link}`
      } else {
        let newCode = this.invitationCode ? '&' + `invitationCode=${this.invitationCode}` : ''
        if (this.token) {
          window.location.href = `http://space.superex.news/user?secretKey=${this.token}${newCode}`;
        } else {
          window.location.href = `http://space.superex.news${invitationCode}`;
          // window.location.href = `${this.linkUrl}/login?redirect_to=http://space.superex.news/user`
        }
      }
    }
  }
};
</script>
<style lang="scss" module>
@import "../../assets/css/uemo-scss/scss/abstracts/mixins";
@import '../../assets/css/mixin';

body[data-has-scroll="1"] {
  .m-site-nav {
    border-color: transparent;

    &::after {
      width: 200%;

      opacity: 0;
    }
  }

  .m-site {
    border-color: rgba(255, 255, 255, 0.2);

    &::after {
      width: 100% !important;
      opacity: 1;
    }
  }
}

.m-site-nav {
  font-size: 14px;
  line-height: 1.6;
  position: relative;
  padding: 0 20px;
  border-radius: 100px;
  gap: 10px;
  font-weight: 500;

  &::after {
    position: absolute;
    z-index: 0;
    top: 0%;
    left: 50%;

    overflow: hidden;

    width: 100%;
    height: 100%;

    content: "";
    transition: 0.36s ease opacity, 0.46s ease width;
    transform: translateX(-50%);
    pointer-events: none;

    border: 1px solid rgba(255, 255, 255, 0.2);;
    border-radius: 100px;
    background: rgba(28, 28, 28, 0.4);

    backdrop-filter: blur(25px);
  }

  &.m-site {
    &::after {
      background: rgba(0, 0, 0, 0) !important;
      @include border_color('border-color-affter');
    }

    .nav-item {
      .item-inner {
        @include font_color('font-color');

        &.itemActive {
          @include font_color('font-color-10');

          .ic {
            transform: rotate(180deg)
          }
        }

        .text {
          @include font_color('font-color-2');
        }

        &:hover {
          @include font_color('font-color-10');

        }
      }
    }

    .dot {
      @include background_color('font-color');
    }
  }


  .btn--show-service {
    position: relative;
    cursor: pointer;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 0 4px;

    .dot-group {
      gap: 4px;
      width: 20px;
    }

    .dot {
      @include circle(4px, rgba(var(--g--color-font--default), 1));
      transition: 0.12s ease;
      transition-delay: calc(var(--delay) * 0.06s);
    }

    &:hover {
      .dot {
        @include background_color('font-color-10');
      }

      .service-nav {
        --nav-sub--inner-y: 0;
        --opacity: 1;

        visibility: visible;

        pointer-events: initial;

        opacity: 1;
      }
    }
  }

  .serviceActive {
    .dot {
      @include background_color('font-color-10')
    }
  }

  .nav-item {
    position: relative;
    z-index: var(--g--z-index);

    padding: 18px 5px;

    cursor: pointer;

    .item-inner {
      white-space: nowrap;
    }

    &:hover {
      & > .item-inner {
        color: #ffc72b;
      }

      .nav-item--sub {
        --nav-sub--inner-y: 0;
        --opacity: 1;
        visibility: visible;

        pointer-events: initial;

        opacity: 1;
      }
    }

    & > .item-inner {
      transition: color 0.26s ease;
      // ====
      // color: rgba(var(--g--color-font--default), 0.6);
      color: #FFF;

      .ic {
        margin-left: 6px;
      }
    }
  }
}

@media (max-width: 1680px) {
  .m-site-nav {
    padding: 0 14px;
    gap: 6px
  }
}

@media (max-width: 1600px) {
  @-moz-document url-prefix() {
    .dot-group {
      width: 16px !important;
    }
  }

  // @-moz-document url-prefix() {
  .m-site-nav {
    padding: 0 14px;
    gap: 4px
  }

  .nav-item {
    font-size: 13px;
  }

  .btn--show-service {
    .dot-group {
      gap: 3px !important;
      width: 14px !important;
      height: 14px !important;

      .dot {
        width: 3px;
        height: 3px;
      }
    }
  }

  // }
}

@media (max-width: 1440px) {
  @-moz-document url-prefix() {
    .dot-group {
      width: 16px !important;
    }
  }

  // @-moz-document url-prefix() {
  .m-site-nav {
    padding: 0 10px;
    gap: 2px
  }

  .nav-item {
    font-size: 12px;
  }

  .btn--show-service {
    .dot-group {
      gap: 3px !important;
      width: 14px !important;
      height: 14px !important;

      .dot {
        width: 3px;
        height: 3px;
      }
    }
  }

  // }
}

@media (max-width: 1366px) {
  @-moz-document url-prefix() {
    .dot-group {
      width: 16px !important;
    }
  }

  // @-moz-document url-prefix() {
  .m-site-nav {
    padding: 0 10px;
    gap: 2px
  }

  .nav-item {
    font-size: 11px;
  }
  .btn-group--icon {
    margin-left: 4px !important;
  }
  .btn--show-service {
    .dot-group {
      gap: 2px !important;
      width: 13px !important;
      height: 13px !important;

      .dot {
        width: 2.5px;
        height: 2.5px;
      }
    }
  }

  // }
}
</style>
