<!--
 * @Description: 站点地图
 * @Author: F-Stone
 * @LastEditTime: 2023-10-30 14:24:10
-->

<template>
  <div style="background-color: #17171A;">
    <div :class="$style['m-site-map']">
      <div class="m-container">
        <div :class="$style['container-inner']">
          <div :class="$style['social-contact']" class="flex justify-between items-center">
            <div :class="$style['state--pos-left']">
              <div :class="$style['nav-logo']" class="flex items-center">
                <img :class="$style['logo-img']" src="../../assets/img/logo.png" alt="superex"/>
              </div>
            </div>
            <div :class="$style['state--pos-right']">
              <div :class="$style['social-group']" class="grid grid-flow-col-dense">
                <div v-for="(item, index) in socialContactList" :key="index" :class="$style['social-item']">
                  <a v-if="item.sub.length < 2 && item.sub.length" :class="$style['item-inner']"
                     class="flex justify-center items-center" :href="item.sub[0].url" target="_blank">
                    <UeIcon :name="item.icon"/>
                  </a>
                  <button v-if="item.sub.length > 1" :class="$style['item-inner']"
                          class="flex justify-center items-center">
                    <UeIcon :name="item.icon"/>
                  </button>
                  <div v-if="item.sub.length > 1">
                    <div v-if="item.sub" :class="$style['group--sub']">
                      <div :class="$style['group--sub-inner']" class="grid grid-cols-2">
                        <a v-for="(
                                                subItem, subIndex
                                            ) in item.sub" :key="subIndex" target="_blank" :href="subItem.url"
                           :class="$style['sub-item']">
                                                    <span :class="$style['text']">{{
                                                        subItem.title
                                                      }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="$style['site-map']" class="flex justify-between">
            <div v-for="(item, index) in siteMapList" :key="index" :class="$style['col-group']">
              <div :class="$style['group-title']">
                <span class="text">{{ item.title }}</span>
              </div>
              <div :class="$style['group-body']">
                <div :class="$style['sub-group']" class="grid">
                  <p v-for="(subItem, subIndex) in item.sub" :key="subIndex">
                    <a v-if="subItem.link" target="_blank" :href="subItem.link"
                       :class="$style['sub-item']">
                                            <span :class="$style['text']">{{
                                                subItem.title
                                              }}</span>
                    </a>
                    <a v-else :class="$style['sub-item']" :href="subItem.url"> <span
                        :class="$style['text']" @click="$router.push(subItem.url)">{{
                        subItem.title
                      }}</span></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style['g-foot']">
      <div class="m-container">
        <div @click="languageModel=true" :class="$style['container-inner']" class="flex items-center justify-between">
          <div :class="$style['state--pos-left']">
            <div :class="$style['copyright']">
              <span style="color: #FFF;" class="text"> &copy; 2024 SUPERDAO INC. </span>
            </div>
          </div>
          <div :class="$style['state--pos-right']">
            <button :class="$style['btn--lang']" class="flex items-center">
              <span style="color: #FFF;" class="text">{{ languageName }}</span>
              <UeIcon :class="$style['ic']" name="earth"/>
            </button>
          </div>
        </div>
      </div>
    </div>
    <Language :languageModel="languageModel" @closeFun="languageModel=false"/>
  </div>
</template>
<script>
// import { socialContactList, siteMapList } from "@superex/public/data/site-map";
import {mapGetters} from 'vuex'
import Language from "../publicModel/Language";
import urls from "@/utils/baseUrl";
import {linksApi} from "@/apis/user";

export default {
  name: '',
  components: {
    Language
  },
  data() {
    return {
      languageModel: false,
      linksObj: {},
      socialContactList: [
        {
          icon: "telegram",
          title: "telegram",
          type: "telegram",
          sub: [],
        },
        {
          icon: "twitter",
          title: "twitter",
          type: "twitter",
          sub: [],
        },
        {
          icon: "ins",
          title: "ins",
          type: "ins",
          link: "",
          sub: []
        },
        {
          icon: "medium",
          title: "medium",
          type: "medium",
          link: "",
          sub: []
        },
        {
          icon: "discord",
          title: "discord",
          type: "discord",
          link: "",
          sub: []
        },
        {
          icon: "youtube",
          title: "youtube",
          type: "youtube",
          link: "",
          sub: []
        },
        {
          icon: "facebook",
          title: "facebook",
          type: "facebook",
          link: "",
          sub: []
        },
        {
          icon: "cmc",
          title: "cmc",
          type: "cmc",
          link: "",
          sub: []
        },
      ],
      siteMapList: [
        {
          title: this.$t('400037'),
          sub: [
            {
              title: this.$t('400038'),
              link: this.language == 'zh-TC' ? 'https://support.superex.com/hc/zh-hk/articles/4413122414105' : this.language == 'ko' ? 'https://support.superex.com/hc/en-001/articles/11479008598553' : 'https://support.superex.com/hc/en-001/articles/11479008598553',
              url: ""
            },
            {
              title: this.$t('400039'),
              link: this.language == 'zh-TC' ? 'https://support.superex.com/hc/zh-hk/articles/11461213305625' : this.language == 'ko' ? 'https://support.superex.com/hc/en-001/articles/11478863478809' : 'https://support.superex.com/hc/en-001/articles/11478863478809',
              url: ""
            },
            {
              title: this.$t('400040'),
              link: this.language == 'zh-TC' ? 'https://support.superex.com/hc/zh-hk/categories/4410470420249' : this.language == 'ko' ? 'https://support.superex.com/hc/ko/categories/4410470420249' : 'https://support.superex.com/hc/en-001/categories/4410470420249',
              url: ""
            },
            {
              title: this.$t('8100015'), //媒体工具包 8100015
              link: '',
              url: `${urls.linkUrl}/mediaMaterial`
            },
          ],
        },
        {
          title: this.$t('400004'),
          sub: [
            {
              title: this.$t('400042'),
              link: this.language == 'zh-TC' ? 'https://support.superex.com/hc/zh-hk/categories/4412787796889-%E6%96%B0%E6%89%8B%E4%B8%AD%E5%BF%83' : this.language == 'ko' ? 'https://support.superex.com/hc/ko/categories/4412787796889-%EC%B4%88%EB%B3%B4%EC%9E%90-%EC%95%84%EC%B9%B4%EB%8D%B0%EB%AF%B8' : 'https://support.superex.com/hc/en-001/categories/4412787796889-Beginner-s-Center',
              url: ""
            },
            {
              title: this.$t('400043'),
              link: this.language == 'zh-TC' ? 'https://support.superex.com/hc/zh-hk/categories/4410478890137-%E7%8F%BE%E8%B2%A8-%E6%9D%A0%E6%9D%86%E4%BA%A4%E6%98%93' : this.language == 'ko' ? 'https://support.superex.com/hc/ko/categories/4410478890137-%ED%98%84%EB%AC%BC-%EB%A0%88%EB%B2%84%EB%A6%AC%EC%A7%80-%EA%B1%B0%EB%9E%98' : 'https://support.superex.com/hc/en-001/categories/4410478890137-Spot-Leveraged-Trading',
              url: ""
            },
            {
              title: this.$t('400044'),
              link: this.language == 'zh-TC' ? 'https://support.superex.com/hc/zh-hk/categories/4410478992793-%E5%90%88%E7%B4%84%E4%BA%A4%E6%98%93' : this.language == 'ko' ? 'https://support.superex.com/hc/ko/categories/4410478992793-%EC%84%A0%EB%AC%BC-%EA%B1%B0%EB%9E%98' : 'https://support.superex.com/hc/en-001/categories/4410478992793-Futures-Trading',
              url: ""
            },
            {
              title: this.$t('400045'),
              link: this.language == 'zh-TC' ? 'https://support.superex.com/hc/zh-hk/categories/4412774906905-%E5%B8%B8%E8%A6%8B%E5%95%8F%E9%A1%8C' : this.language == 'ko' ? 'https://support.superex.com/hc/ko/categories/4412774906905-FAQ' : 'https://support.superex.com/hc/en-001/categories/4412774906905-FAQ',
              url: ""
            },
          ],
        },
        {
          title: this.$t('400046'),
          sub: [
            {
              title: this.$t('400047'),
              link: "",
              url: `${urls.linkUrl}/userCenter/fee/trading`
            },
            {
              title: this.$t('400048'),
              link: "",
              url: `${urls.linkUrl}/rule`
            },
            {
              title: this.$t('400049'),
              link: this.language == 'zh-TC' ? 'https://docs.google.com/forms/d/e/1FAIpQLSep8jqJSbo9fvN5F1YLYwyWzKaSGc3G_y6vzFfikvQTLcGLIQ/viewform' : this.language == 'ko' ? 'https://docs.google.com/forms/d/e/1FAIpQLSe-v5-CgXpFudeOf8jX_mtsQlXCez41e3AOvT-p8zFo64gY7g/viewform' : 'https://docs.google.com/forms/d/e/1FAIpQLSd0zIRGvBWB2Bv_fmiwqSMTx5vAcaPi7n443Jjj49UG8j-kOg/viewform',
              url: ""
            },
            {
              title: this.$t('400050'),
              link: "",
              url: `${urls.linkUrl}/userCenter/commission`
            },
            {
              title: "DAO",
              link: this.language == 'zh-TC' ? 'https://support.superex.com/hc/zh-hk/articles/6736594090777-SuperEx%E5%85%A8%E7%90%83DAO%E6%8B%9B%E5%8B%9F' : this.language == 'ko' ? 'https://support.superex.com/hc/ko/articles/6736567169689-SuperEx-%EA%B8%80%EB%A1%9C%EB%B2%8C-DAO-%ED%8C%8C%ED%8A%B8%EB%84%88-%EB%AA%A8%EC%A7%91' : 'https://support.superex.com/hc/en-001/articles/6736531883033-SuperEx-Global-DAO-Recruitment',
              url: ""
            },
            {
              title: this.$t('400291'), //ET专区
              link: "",
              url: `${urls.linkUrl}/advanced/et`
            },
            {
              title: 'APIs',
              link: "",
              url: `${urls.linkUrl}/apiKeyHome`
            },
            {
              title: this.$t('8100013'), // 官方验证通道
              link: "",
              url: `${urls.linkUrl}/verification`,
            },
          ],
        },
        {
          title: this.$t('400051'),
          sub: [
            {
              title: this.$t('400052'),
              link: this.language == 'zh-TC' ? 'https://support.superex.com/hc/zh-hk' : this.language == 'ko' ? 'https://support.superex.com/hc/ko' : 'https://support.superex.com/hc/en-001',
            },
            {
              title: this.$t('8100000'),
              link: "",
              url: `${urls.linkUrl}/feedback`
            },
            {
              title: this.$t('400053'),
              link: "https://support.superex.com/hc/en-001/requests/new",
            },
            {
              title: this.$t('400054'),
              link: "mailto:business@superex.com",
            },
            {
              title: this.$t('400055'),
              link: "mailto:gbg@superex.com",
            },
          ],
        },
        {
          title: "SCS",
          sub: [
            {title: "SCS Chain", link: "https://scschain.com/"},
            {title: this.$t('400239'), link: "https://scschain.com/stayTuned.html#module-contact"},
            {title: this.$t('400240'), link: "https://testnetscan.scschain.com/"},
            {
              title: this.$t('400241'),
              link: "https://superexchain.github.io/?_gl=1*qwdy0*_ga*NDQ5OTYxNTE1LjE2OTc2ODU3MTk.*_ga_W94SCVN3FF*MTY5OTg1MzU5Ni4xMjMuMS4xNjk5ODY0ODI5LjYwLjAuMA.."
            },
          ],
        },
        {
          title: this.$t('300135'),
          sub: [
            {title: this.$t('400234'), link: "", url: `${urls.linkUrl}/dao`},
            {title: this.$t('400235'), link: "", url: `${urls.linkUrl}/roadmap`},
            {title: this.$t('400236'), link: "", url: `${urls.linkUrl}/userCenter/commission`},
            {title: this.$t('400245'), link: "", url: `${urls.linkUrl}/noviceGuide/sevenDay`},
          ],
        },
      ]
    }
  },
  computed: {
    ...mapGetters(['currency', 'languageName', 'language', 'token', 'isDirection']),
    linkUrl() {
      return urls.linkUrl
    }
  },
  watch: {},
  async created() {
  },
  methods: {
    getLinksApi() {
      this.linksObj = {
        twitter: [],
        telegram: [],
        medium: [],
        INS: [],
        Discord: [],
        YouTube: [],
        Facebook: [],
        CMC: []
      }
      linksApi().then(res => {
        if (res.data) {
          res.data.forEach(v => {
            if (v.type == 1) {
              this.socialContactList[1].sub.push(v)
            } else if (v.type == 2) {
              this.socialContactList[0].sub.push(v)
            } else if (v.type == 3) {
              this.socialContactList[3].sub.push(v)
            } else if (v.type == 4) {
              this.socialContactList[2].sub.push(v)
            } else if (v.type == 5) {
              this.socialContactList[4].sub.push(v)
            } else if (v.type == 6) {
              this.socialContactList[5].sub.push(v)
            } else if (v.type == 7) {
              this.socialContactList[6].sub.push(v)
            } else if (v.type == 8) {
              this.socialContactList[7].sub.push(v)
            } else {
              return false
            }
          })
          console.log(this.socialContactList)
        }
      })
    }
  },
  mounted() {
    this.getLinksApi()
  },
  destroyed() {
  },
}
</script>
<style lang="scss" module>
@import "../../assets/css/uemo-scss/scss/abstracts/mixins";
@import "../../assets/css/uemo-scss/scss/abstracts/_breakpoint";

.m-site-map {
  padding: 90px 0 70px;

  .logo-img {
    height: 40px;
  }
}

.social-group {
  gap: 26px;
}

.social-item {
  position: relative;
  z-index: var(--g--z-index);

  &:hover {
    .item-inner {
      color: #000922;
      background-color: #FFC72B;
    }

    .group--sub {
      visibility: visible;

      pointer-events: initial;

      opacity: 1;
    }

    .group--sub-inner {
      transform: translateY(0);
    }
  }

  .item-inner {
    @include circle(40px);
    font-size: 22px;

    transition: 0.26s ease;

    color: rgba(var(--g--color-font--default), 0.5);
  }

  .group--sub {
    position: absolute;
    top: 100%;
    left: 50%;

    visibility: hidden;

    padding-top: 15px;

    transition: 0.26s ease;
    transform: translateX(-50%);
    pointer-events: none;

    opacity: 0;
    color: rgba(var(--g--color-font--default), 0.5);

    backdrop-filter: blur(8px);
  }

  .group--sub-inner {
    font-size: 13px;
    line-height: 1.4;

    width: 400px;
    padding: 20px 28px;

    transition: 0.26s ease;
    transform: translateY(20px);

    border: 1px solid rgba(234, 236, 240, 0.2);
    border-radius: 8px;
    background: rgba(22, 23, 25, 0.8);

    gap: 2px 10px;

    &::after {
      @include square(14px);
      position: absolute;
      bottom: calc(100% - 6px);
      left: 50%;

      content: "";
      transform: translateX(-50%) rotate(45deg);

      border: 2px solid rgba(234, 236, 240, 0.2);
      border-right-width: 0;
      border-bottom-width: 0;
      background: rgba(22, 23, 25, 1);
    }

    .sub-item {
      cursor: pointer;
      @include ellipse(1);
      padding: 8px 0;
      transition: 0.26s ease;

      &:hover {
        color: rgba(var(--g--color-font--default), 1);
      }

      span {
        cursor: pointer;
      }
    }
  }

  a {
    color: inherit;
  }
}

.site-map {
  margin-top: 57px;
  padding-top: 57px;

  border-top: 1px solid rgba(#dfe9fb, 0.15);

  .group-body {
    font-size: 14px;
    line-height: 1.6;
    color: rgba(var(--g--color-font--default), 0.5);
  }

  .group-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 30px;
    color: #FFF !important;

    span {
      color: #FFF !important;
    }
  }

  .sub-group {
    gap: 18px;

    a {
      color: inherit;
    }
  }

  .sub-item {
    cursor: pointer;

    &:hover {
      color: rgba(var(--g--color-font--default), 0.7);

      .text::after {
        width: 100%;
      }
    }

    .text {
      position: relative;

      transition: 0.36s ease;

      &::after {
        position: absolute;
        bottom: -0.3em;
        left: 0;

        width: 0%;
        height: 2px;

        content: "";
        transition: 0.36s ease;

        background-color: rgba(var(--g--color-font--default), 0.7);
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .m-site-map {
    .logo-img {
      height: 30px;
    }
  }

  .social-group {
    gap: 20px;
  }

  .social-item {
    .item-inner {
      @include circle(34px);
      font-size: 20px;
    }
  }

  .site-map {
    margin-top: 47px;
    padding-top: 47px;

    .group-body {
      font-size: 13px;
    }

    .group-title {
      font-size: 15px;

      margin-bottom: 24px;
    }

    .sub-group {
      gap: 12px;
    }
  }
}

.g-foot {
  color: rgba(var(--g--color-font--default), 0.6);

  .container-inner {
    padding: 22px 0;

    border-top: 1px solid rgba(#dfe9fb, 0.15);
  }

  .copyright {
    font-size: 13px;
  }

  .btn--lang {
    font-size: 12px;

    padding: 7px 16px;

    border: 2px solid rgba(#fff, 0.3);
    border-radius: 100px;

    .ic {
      font-size: 16px;

      margin-left: 4px;
    }
  }
}

@include media-breakpoint-down(xxl) {
  .copyright {
    font-size: 12px;
  }
}
</style>
